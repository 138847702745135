import React from 'react';

function Error({ message }) {
  return (
    <div className=" text-red-700 px-4 py-3 rounded relative" role="alert">
      <span className="block sm:inline text-5xl">Oops! This page does not exist!</span>
    </div>
  );
}

export default Error;
