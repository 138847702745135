import React from 'react';
import footerLogo from '../../footerLogo.svg';
import githubIconDark from  '../../githubIconDark.png';
import instaDark from  '../../instaDark.png';
import linkedinDark from  '../../linkedinIcon.png';
import emailDark from  '../../emailDark.png';


function Footer() {
  return (
    
    <footer className=" bg-p4 p-8 flex md:flex-row gap-10 md:gap-20  justify-center md:items-start  flex-wrap text-p1">
         
        <div className='max-w-96 w-1/4 min-w-60 flex flex-col items-center'>
           <img src={footerLogo} />
           <p className='text-xs text-justify '>MERN Stack Developer</p>

        </div>
        <div className='w-fit flex flex-col gap-3'>
            <h5 className='underline underline-offset-4 font-bold'>Site Map</h5>
            <a href='#home'>About me</a>
            <a href='#projects'>Projects</a>
            <a href='#photowall'>Visual Portfolio</a>
            <a href='#contactme'>Contact me</a>
        </div>
        <div className='w-fit flex flex-col gap-3'>
        <h5 className='underline underline-offset-4 font-bold'>Useful Links</h5>

        <a href='https://instagram.com/vsnx_o' target='_blank' className='flex gap-2'><img src={instaDark} className='max-w-6 bg-p1 rounded-md' alt='Demo' />vsnx_o</a>
        <a href='https://github.com/kenwaysharma' target='_blank' className='flex gap-2'><img src={githubIconDark} className='max-w-6 bg-p1 rounded-md' alt='Demo' />kenwaysharma</a>
        <a href='https://www.linkedin.com/in/varun-sharma-1276051a9/' target='_blank' className='flex gap-2'><img src={linkedinDark} className='max-w-6 bg-p1 rounded-md' alt='Demo' />Varun Sharma</a>

        </div>
        <div className='w-fit flex flex-col gap-3'>
         <h5 className='underline underline-offset-4 font-bold'>Contact Me</h5>
         <a href='mailto: kenwaysharma@gmail.com' target='_blank' className='flex gap-2'><img src={emailDark} className='max-w-6 p-0.5 bg-p1 rounded-md' alt='Demo' />kenwaysharma@gmail.com</a>
         <a href='https://www.linkedin.com/in/varun-sharma-1276051a9/' target='_blank' className='flex gap-2'><img src={linkedinDark} className='max-w-6 bg-p1 rounded-md' alt='Demo' />Varun Sharma</a>


        </div>
       
        <div className='min-w-full flex justify-center'>
            <p>&copy; 2024 Varun Sharma. All Rights Reserved.</p>
        </div>
   
    </footer>

  )
}

export default Footer