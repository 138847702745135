import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import Gallery from './components/photoGallery';
import Footer from './components/footer';
import Projects from './components/projects';
import githubIconDark from  './githubIconDark.png';
import instaDark from  './instaDark.png';
import AboutMe from './components/aboutme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Loading } from './components/photoGallery/Card';


function App() {
  return (
    <div className="container min-h-dvh max-w-full bg-slate-800">
      {/* 1st Section */}
      <div id='home'></div>
      <div className='min-h-full max-w-full bg-p1 sticky top-0 z-50 flex justify-center'>
          <Navbar />
      </div>
      <div className='min-h-full max-w-full bg-p1 flex flex-col items-center '>
          <AboutMe />
      </div>
      {/* 2nd Section */}
      <div id='projects'></div>
      <div className=' min-h-full max-w-full bg-p2 p-8 flex justify-center flex-col items-center'>
        <h2 className='text-5xl mb-10 text-p3 underline underline-offset-4'>Projects & Demos</h2>
      <Projects />
      <div className='w-fit mt-5 rounded-xl bg-slate-500 p-5 text-p2'>
        <p >Discover additional projects on my github profile! </p>
        <a href='https://github.com/kenwaysharma' target='_blank' className='flex gap-2'><LazyLoadImage src={githubIconDark} className='max-w-6' alt='Demo' loading={<Loading />}/>github.com/kenwaysharma</a>
      </div>
      </div>
      {/* 3rd Section */}
      <div id='gallery' className='min-h-full max-w-full bg-p3 flex justify-center flex-col items-center '>
        <h2 className='text-5xl m-10  text-p2 underline underline-offset-4 self-center'>Visual Portfolio</h2>

          <Gallery />
        <div className='w-fit mt-5 rounded-xl bg-slate-200 p-5 text-p3 self-center m-10 '>
        <p>Browse through more of my photography on my instagram profile!</p>
        <a href='https://instagram.com/vsnx_o' target='_blank' className='flex gap-2'><LazyLoadImage src={instaDark} className='max-w-6' alt='Demo' loading={<Loading />}  />@vsnx_o</a>
      </div>
      </div>
      {/* 4th Section */}
      <div id='contactme' className='min-h-full max-w-full bg-p4'>
          <Footer />
      </div>
    </div>
  );
}

export default App;
