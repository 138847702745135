import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import pic1 from '../../pic1.jpg'
import pic2 from '../../pic2.JPG'
import pic3 from '../../pic3.jpg'
import pic4 from '../../pic4.JPG'
import pic5 from '../../pic5.JPG'
import pic6 from '../../pic6.JPG'
import pic7 from '../../pic7.jpg'
import pic8 from '../../pic8.jpg'
import pic9 from '../../pic9.JPG'
import pic10 from '../../pic10.JPG'
import pic11 from '../../pic11.JPG'
import pic12 from '../../pic12.jpg'
import pic13 from '../../pic13.JPG'
import pic14 from '../../pic14.JPG'
import pic15 from '../../pic15.JPG'
import pic16 from '../../pic16.jpg'
import { Card } from './Card';






  
 
function Gallery() {
  const [loading, setLoading] = useState(false)
  const images = [
    pic1, 
    pic2 ,
    pic3 ,
    pic4 ,
    pic5 ,
    pic6 ,
    pic7 ,
    pic8 ,
    pic9 ,
    pic10,
    pic11,
    pic12,
    pic13,
    pic14,
    pic15,
    pic16
    
    

    
  ];



  
  

  return (
    <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
    className='container p-8 min-h-80 max-w-5xl'>

        
       
                <Masonry
                columnsCount={3}
                gutter={10}
                >
{
          loading?"Loading...":
        images.map((imgSrc, index) => (
          <Card key={index} imgSrc={imgSrc} />
        ))}
        
        

          </Masonry>
          
            </ResponsiveMasonry>

  
  )
}

export default Gallery