import React, { useEffect } from 'react';
import project1 from  '../../p1.png'
import project2 from  '../../p2.png'
import reactFormValidation from  '../../p3.png'
import reactBlogWebsite from  '../../p4.png'



import { useNavigate } from 'react-router-dom';
import linkIcon from  '../../linkIcon.png';
import githubIcon from  '../../githubIcon.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Loading } from '../photoGallery/Card';

const projectsData = [
    {
      id: 1,
      title: "Figma to React Conversion Demo",
      description: "Demonstrates the conversion of pixel-perfect Figma designs to React code.",
      demoLink: "https://elegant-heliotrope-add4a8.netlify.app/",
      githubLink: "https://github.com/kenwaysharma/figma-to-react-design-conversion-demo",
      image: project1
    },
    {
      id: 2,
      title: "Blog Website",
      description: "A full-stack blog website built using the MERN stack, allowing users to add comments, create accounts, and perform signup/signin functionalities.",
      demoLink: "",
      githubLink: "https://github.com/kenwaysharma/react-blog-website.git",
      image: reactBlogWebsite
    },
    {
      id: 3,
      title: "React Form Validation",
      description: "A simple demonstration of form validation in React.",
      demoLink: "https://react-form-validation2124.netlify.app/",
      githubLink: "https://github.com/kenwaysharma/react-form-validation-demo",
      image: reactFormValidation
    },
    {
      id: 4,
      title: "CRUD API Using Node.js and MongoDB",
      description: "An API demonstrating CRUD (Create, Read, Update, Delete) operations using Node.js and MongoDB for database management.",
      demoLink: "",
      githubLink: "https://github.com/kenwaysharma/node-express-mongoDB-crud-demo?",
      image: project2
    },
    // Add more projects here as needed
  ];
function Projects() {
  useEffect(() => {
    // Any initialization code
  }, []);

  const navigate = useNavigate();

  return (
    <div className='p-2 min-h-80 flex flex-col flex-wrap justify-center w-full max-w-6xl gap-10'>
      {projectsData.map((project) => (
        <div key={project.id} className={`flex flex-col gap-10 w-full justify-center items-center ${project.id % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row '}`}>
          <div className='w-full md:w-3/4 min-w-32 max-w-xl'>
            <LazyLoadImage src={project.image} className='rounded-xl' alt={project.title} loading={<Loading />}/>
          </div>
          <div className='w-full md:w-1/4 flex flex-col gap-2'>
            <h3 className='text-xl font-bold text-p3'>
              {project.title}
            </h3>
            <p className='text-p3'>
              {project.description}
            </p>
            <div className='flex items-center gap-5'>
                {project.demoLink?<button onClick={() => window.open(project.demoLink)} className='flex border-2 rounded-md border-orange-400 text-orange-400 p-2 hover:bg-orange-300 hover:text-white transition-colors ease-in-out'>
                View Demo
                <img src={linkIcon} className='max-w-6' alt='Demo' />
              </button>:''}
              
              <a target='_blank' rel="noreferrer" href={project.githubLink}><img src={githubIcon} className='max-w-6' alt='GitHub' /></a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Projects;
